@import '../../styles/sass/breakpoints';

@keyframes blink {
  from {
    opacity: 1;
    transform: rotate(0deg) scale(1, 1);
  }
  to {
    opacity: 0;
    transform: rotate(360deg) scale(0.2, 0.2);
  }
}

@keyframes opacityBlink {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes passing {
  0% {
    filter: hue-rotate(0deg);
  }
  50% {
    filter: hue-rotate(10deg);
  }
  100% {
    filter: hue-rotate(0deg);
  }
}

@keyframes neon {
  from {
    box-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 12px #fff, 0 0 15px #fd397a,
      0 0 20px #fd397a, 0 0 25px #fd397a, 0 0 30px #fd397a, 0 0 35px #fd397a;
  }
  to {
    box-shadow: 0 0 2px #fff, 0 0 5px #fff, 0 0 8px #fff, 0 0 10px #fd397a,
      0 0 12px #fd397a, 0 0 15px #fd397a, 0 0 20px #fd397a, 0 0 25px #fd397a;
  }
}

@keyframes moveToFront {
  0% {
    transform: translate(0, 0);
  }
  14.28% {
    transform: translate(20rem, 0);
  }
  28.54% {
    transform: translate(20rem, 0);
    z-index: 10;
  }
  42.82% {
    transform: translate(0rem, 0);
  }
  57.1% {
    transform: translate(0rem, 0);
  }
  71.38% {
    transform: translate(20rem, 0);
  }
  85.66% {
    transform: translate(20rem, 0);
    z-index: 0;
  }
  100% {
    transform: translate(0rem, 0);
  }
}

.wrapper-1-1,
.wrapper-1-2,
.wrapper-1-3,
.wrapper-1-4 {
  width: 100%;
  margin: 0 auto;
}

@include respond-to(bp3) {
  .wrapper-1-2,
  .wrapper-1-3,
  .wrapper-1-4 {
    width: 50%;
  }
}

@include respond-to(bp4) {
  .wrapper-1-3 {
    width: calc(100% / 3);
  }
  .wrapper-1-4 {
    width: calc(100% / 4);
  }
}

#landing-page {
  font-family: 'Poppins', sans-serif;
  font-size: 1em;
  line-height: 1.4;
  color: #353535;
  background-color: #fff;
  h1 {
    font-size: 2rem;
    line-height: 3rem;
    font-weight: 500;
    position: relative;
    @include respond-to(bp4) {
      font-size: 2.8rem;
      line-height: 4rem;
    }
  }

  .section-title {
    max-width: 22rem;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      font-family: 'Roboto', sans-serif;
      font-size: 7rem;
      font-weight: bold;
      line-height: 7rem;
      color: #fd397a;
      @include respond-to(bp3) {
        font-size: 8rem;
      }
    }
    h2 {
      font-size: 2rem;
      line-height: 3rem;
      margin: 0 0 0 1rem;
      @include respond-to(bp3) {
        font-size: 2.5rem;
        line-height: 3.5rem;
      }
      strong {
        font-weight: bold;
      }
    }
  }

  .container {
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;
  }

  .flex-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    @include respond-to(bp3) {
      flex-flow: row wrap;
    }
  }

  .row-reverse {
    flex-direction: row-reverse;
  }
  .main-cta {
    width: 100%;
    padding: 0.5rem;
    background: transparent;
    border-radius: 1rem;
    border: none;
    outline: none;
    // border: 2px solid #36e481;

    a {
      position: relative;
      display: block;
      font-size: 0.9rem;
      text-align: center;
      background-color: #fd397a;
      color: #fff !important;
      padding: 15px 0;
      text-decoration: none !important;
      border-radius: 2rem;
      text-decoration: none;
      transition: all 0.3s ease;
      z-index: 2;
      @include respond-to(bp3) {
        font-size: 1rem;
      }
      &::before {
        content: '';
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        border-radius: 2rem;
        background: linear-gradient(to right, #8000ff 33%, #fd397a 80%);
        opacity: 0;
        transition: all 0.3s ease;
        z-index: -1;
      }
    }
    &:hover {
      background: transparent;
      a {
        &::before {
          opacity: 1;
          animation: passing 2s infinite;
        }
      }
    }
    &:active {
      a {
        box-shadow: 0px 0px 4px #b5b5b5;
      }
    }
  }

  .connect-wrapper {
    width: 80%;
    height: 90%;
    background: #ffff;
    position: absolute;
    z-index: 1;
    .connect-header {
      width: 100%;
      height: 35%;
      .logo-wrapper {
        width: 100%;
        height: 100%;
        background: #fff;
        transition: all 0.3s ease;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .connect-body {
      width: 100%;
      height: 65%;
      .gradient-wrapper {
        height: 100%;
        transition: all 0.3s ease;
        .link-wrapper {
          width: 100%;
          height: 100%;
          padding: 2rem 1rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          grid-gap: 1rem;
          gap: 0.5rem;
          button {
            width: 100%;
            border: none;
            background: transparent;
            padding: 0.5rem;
            &.active {
              border: 1px solid #fff;
              border-radius: 2px;
            }
            a {
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #232323;
              border-radius: 2px;
              background: #fff;
              padding: 0.6rem;
              font-size: 0.8rem;
            }
          }
        }
        .connect-footer {
          width: 100%;
          img {
            width: 100%;
          }
        }
      }
    }
  }

  header {
    width: 100%;
    padding: 2rem 0 0;
    background: #ebebf2;
    @include respond-to(bp4) {
      padding: 2rem 1rem;
    }
    .container {
      flex-direction: row;
      max-width: 1060px;
      margin: 0 auto;
    }
    img {
      height: 1.5rem;
      @include respond-to(bp3) {
        height: 2rem;
      }
    }
    a {
      font-weight: 600;
      font-size: 1rem;
      border-bottom: 2px solid #fd397a;
      color: #232323 !important;
      text-decoration: none !important;
    }
  }
  section {
    padding: 40px 0;
  }
  p {
    font-size: 1rem;
    line-height: 175%;
  }

  .create-store {
    padding: 3.5rem 0 8rem;
    background: linear-gradient(180deg, #ebebf2 0%, rgba(235, 235, 242, 0) 40%);
    @include respond-to(bp3) {
      padding: 3rem 0 0;
    }
    .container {
      // background-image: url(../../assets/landing-page/line-banner.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
    }
    .content {
      max-width: 450px;
    }
    .wrapper-1-1 {
      &.main-title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      &.img-wrapper {
        overflow: hidden;
        figure {
          margin: 0 auto;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: -3rem;
          left: 0.5rem;
          @include respond-to(bp3) {
            margin-top: -5rem;
          }
          img {
            position: relative;
            &.line-bg {
              position: absolute;
              z-index: 0;
              transition: all 0.3s ease;
              animation: 2s ease-in 1s infinite alternate running opacityBlink;
            }
          }
        }
      }
    }

    h1 {
      margin: 0 0 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 1.2rem;
      line-height: 1.5rem;
      strong {
        font-size: 1.8rem;
      }
      @include respond-to(bp3) {
        font-size: 2rem;
        line-height: 3rem;
        strong {
          font-size: 2rem;
        }
      }
    }
    p {
      font-size: 16px;
      margin-bottom: 40px;
    }
    .main-cta {
      width: 90%;
      align-self: center;
      position: absolute;
      bottom: -4rem;
      @include respond-to(bp3) {
        width: 50%;
        position: relative;
        bottom: 0rem;
      }
    }

    figure img {
      width: 100%;
    }
  }

  .checkout {
    padding: 1rem 0 3rem;
    @include respond-to(bp3) {
      padding: 0rem 0 3rem;
    }
    .container {
      flex-direction: column-reverse;
      position: relative;
      @include respond-to(bp3) {
        flex-direction: row-reverse;
      }
    }
    .content {
      max-width: 450px;
    }
    h1 {
      font-size: 1.5rem;
      line-height: 2.5rem;
      @include respond-to(bp4) {
        font-size: 2rem;
        line-height: 3rem;
      }
    }
    p {
      margin: 2rem 0;
    }
    .main-cta {
      width: 90%;
      align-self: center;
      position: absolute;
      bottom: -2rem;
      @include respond-to(bp3) {
        width: auto;
        position: relative;
        bottom: 0rem;
      }
    }
    figure img {
      width: 100%;
    }
  }

  .customize-preview {
    padding: 3rem 0 10rem;
    background: #ebebf2;
    @include respond-to(bp3) {
      padding: 3rem 0 3rem;
    }
    .container {
      flex-direction: column;
      position: relative;
      @include respond-to(bp3) {
        flex-direction: row-reverse;
      }
    }
    .content {
      max-width: 450px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 2rem;
    }

    p {
      margin: 2rem 0;
    }

    .main-cta {
      width: 90%;
      position: absolute;
      bottom: -6.5rem;
      margin: 0 auto;
      @include respond-to(bp3) {
        width: 100%;
        bottom: 0;
        position: relative;
      }
    }

    .customize-buttons-wrapper {
      width: 100%;
      display: none;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
      @include respond-to(bp3) {
        display: flex;
        gap: 2rem;
      }

      &.mobile {
        display: flex;
        @include respond-to(bp3) {
          display: none;
        }
      }

      .color-wrapper {
        width: 3.5rem;
        height: 3.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5rem 0.3rem;
        border: 4px solid transparent;
        border-radius: 50%;
        transition: all 0.3s ease;
        @include respond-to(bp3) {
          width: 4rem;
          height: 4rem;
        }
        .color-button {
          width: 2rem;
          height: 2rem;
          font-size: 0;
          border: none;
          border-radius: 50%;
          outline: none;
          box-shadow: 3px 1px 4px rgba(0, 0, 0, 0.4);
          transition: all 0.3s ease;
          &:hover {
            width: 2.5rem;
            height: 2.5rem;
          }
          @include respond-to(bp3) {
            width: 2.3rem;
            height: 2.3rem;
            &:hover {
              width: 3rem;
              height: 3rem;
            }
          }
        }
        &.active {
          border-color: #fff;
          .color-button {
            width: 2.5rem;
            height: 2.5rem;
            @include respond-to(bp3) {
              width: 3rem;
              height: 3rem;
            }
          }
        }
      }
    }

    .switch-wrapper {
      width: 100%;
      margin: 0rem 0.3rem 2rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @include respond-to(bp3) {
        margin: 2rem 0.3rem;
      }

      .ant-switch {
        min-width: 40px;
        height: 16px;
        line-height: 16px;
        &.ant-switch-checked {
          background-color: #caffe1;
          .ant-switch-handle {
            left: calc(100% - 18px - 0px);
            &::before {
              background-color: #36e481;
            }
          }
        }
        .ant-switch-handle {
          left: 0px;
          top: -1px;
        }
      }
      p {
        margin: 0 0 0 1rem;
      }
    }

    .connect-preview {
      width: 100%;
      max-width: 320px;
      margin: 0 auto;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        position: relative;
        z-index: 2;
      }
    }
  }

  .share {
    padding: 2rem 0 3rem;
    @include respond-to(bp3) {
      padding: 9rem 0;
    }

    .section-title {
      h2 {
        font-size: 1.9rem;
      }
    }

    .container {
      flex-direction: column-reverse;
      position: relative;
      @include respond-to(bp3) {
        flex-direction: row-reverse;
      }
    }
    .content {
      max-width: 450px;
    }
    p {
      margin: 2rem 0;
    }
    .main-cta {
      width: 90%;
      align-self: center;
      position: absolute;
      bottom: -2rem;
      @include respond-to(bp3) {
        width: auto;
        position: relative;
        bottom: 0rem;
      }
    }

    .icons-row {
      display: flex;
      justify-content: center;
      align-items: center;
      figure {
        width: 70px;
        margin: 1rem;
        .icon-whats {
          animation: 2s cubic-bezier(0.96, -0.07, 0.69, 0.68) 3s infinite
            alternate-reverse running blink;
        }
        .icon-pin {
          animation: 2s cubic-bezier(0.07, -0.21, 0.98, -0.32) 1s infinite
            alternate running blink;
        }
        .icon-tiktok {
          animation: 2s cubic-bezier(0.42, 0, 0.93, -0.11) 1s infinite
            alternate-reverse running blink;
        }
        .icon-face {
          animation: 2s cubic-bezier(1, -0.54, 0.1, 0.8) 1s infinite alternate
            running blink;
        }
        .icon-insta {
          animation: 2s ease-in 1s infinite alternate-reverse running blink;
        }
        .icon-yt {
          animation: 2s cubic-bezier(1, 0.09, 0, 2.35) 1s infinite alternate
            running blink;
        }
        .icon-email {
          animation: 2s cubic-bezier(0.96, -0.03, 0.09, 1.14) 1s infinite
            alternate-reverse running blink;
        }
        .icon-linkedin {
          animation: 2s cubic-bezier(0.23, 0.23, 1, -0.12) 1s infinite alternate
            running blink;
        }
        .icon-twitter {
          animation: 2s cubic-bezier(0.88, -0.47, 0.49, 1.13) 1s infinite
            alternate-reverse running blink;
        }

        img {
          &:hover {
            animation: unset;
          }
        }
      }
    }

    figure img {
      width: 100%;
    }
  }

  .platform {
    display: none;
    padding-bottom: 0;
    @include respond-to(bp3) {
      display: flex;
    }
    .content {
      padding: 40px 60px 0;
    }
    p {
      font-size: 18px;
      margin-bottom: 60px;
      text-align: center;
    }
    figure {
      height: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      margin: 0;
      img {
        width: 100%;
        margin: 0 0 -4rem;
      }
    }
    h2 {
      font-size: 1.8rem;
      text-align: center;
      font-weight: 600;
      margin: 0 0 2rem;
      @include respond-to(bp4) {
        font-size: 2.2rem;
      }
    }
  }
  .store-sections,
  .start-now,
  footer {
    background: #6252bf;
  }

  .store-sections {
    margin-top: -20px;
    position: relative;
    padding: 3rem 0 2rem;
    overflow: hidden;

    .content {
      width: 33%;
      padding: 30px 15px;
      margin: 0 30px;
      text-align: center;
    }
    h2 {
      font-size: 1.4rem;
      line-height: 2.2rem;
      font-weight: normal;
      color: #fff;
      text-align: center;
      margin: 0 0 2rem;
      @include respond-to(bp3) {
        font-size: 1.8rem;
      }
    }
    .gif-container {
      width: 90%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      img {
        height: 320px;
        position: relative;
        z-index: 1;
        box-shadow: -5px 6px 6px rgba(0, 0, 0, 0.2),
          inset 5px 6px 6px rgba(0, 0, 0, 0.2);
        border-radius: 1.5rem;
        @include respond-to(bp3) {
          height: 600px;
          border-radius: 3rem;
        }
        &.animated-img {
          position: absolute;
          z-index: 0;
          animation: moveToFront 8s cubic-bezier(0.4, 0, 1, 1) alternate
            infinite;
        }
      }
    }
  }

  .start-now {
    text-align: center;
    padding-bottom: 0;
    h1 {
      color: #fff;
      font-weight: 300;
      font-size: 1rem;
      margin-bottom: 50px;
      line-height: 1.7rem;
      @include respond-to(bp3) {
        font-size: 1.1rem;
        line-height: 2.2rem;
      }
      @include respond-to(bp4) {
        font-size: 1.4rem;
      }
      &:after {
        display: none;
      }
    }

    p {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
    }

    a {
      width: 100%;
      border: 3px solid #fd397a;
      color: #fff;
      font-size: 1rem;
      text-decoration: none;
      padding: 10px 0;
      border-radius: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 40px;
      transition: all 0.3s ease;
      @include respond-to(bp3) {
        width: 50%;
        padding: 10px 80px;
        position: relative;
        margin: 0px auto 1.5rem;
      }

      &:hover {
        border-color: #fff;
        animation: neon 1s alternate infinite;
      }

      svg {
        position: absolute;
        right: 2rem;
        @include respond-to(bp3) {
          right: 1rem;
        }
      }
    }
    span {
      font-size: 12px;
      font-weight: 300;
      display: block;
      color: #fff;
    }
  }
  footer {
    background: linear-gradient(to bottom, #6252bf, #3c2f89);
    .content {
      color: #fff;
      margin: 1rem 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      .row {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 0;
      }
      &.social {
        justify-content: center;
        align-items: center;
        @include respond-to(bp3) {
          width: 33%;
        }
      }
      &.info {
        flex-direction: row;
        justify-content: center;
        @include respond-to(bp3) {
          width: 60%;
        }
        a,
        span {
          font-size: 0.7rem;
          @include respond-to(bp1) {
            font-size: 0.8rem;
          }
          @include respond-to(bp2) {
            font-size: 0.9rem;
          }
          @include respond-to(bp3) {
            font-size: 1rem;
          }
        }
        .content.wrapper-1-3 {
          width: auto;
          margin: 1rem 0.5rem;
          @include respond-to(bp3) {
            width: 50%;
            margin: 1rem 0;
          }
        }
      }
    }
    .line {
      padding: 1rem 0 1rem;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }
    figure img {
      width: 150px;
    }
    a {
      color: #fff;
      margin-bottom: 10px;
      display: inline-block;
      text-decoration: none;
    }
    .social-media {
      .icon {
        margin: 0 0.9rem 0 0;
        img {
          width: 30px;
        }
        &:first-child,
        &:last-child {
          margin: 0;
        }
      }
    }
  }
}
